@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
.navBg{
  background-color: #B3E0FF;
}
.navbar {
  background-color: #fff;
}
.navbar-brand {
  font-size: 1.9rem !important;
  color: #565387 !important;
}
.navbar a {
font-size: 1.3rem;
text-transform: capitalize;
}
.menu_active {
font-weight: bold;
border-bottom: 3px solid #565387;
}
.navbar a:hover{
  color: #4E8CC9 !important;
}

/* section{
  background-color: #B3E0FF;
} */

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header{
  width: 100%;
  max-width: 1366px;
  height: 80px;
  z-index: 997;
  transition: all 0.5s;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);
}
#header.header-scrolled {
  height: 60px;
}
#banner h2{
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color:black;
}
#banner h3{
color:#484848;
font-size: 20px;
line-height: 2rem;
}
#banner .btn-contact{
  font-weight:500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  background: #4E8CC9;
  color: #fff;
  border: 2px solid#4E8CC9;
  text-decoration: none;
}
#banner .btn-contact:hover{
  background: #fff;
  color: #4E8CC9;
  border: 2px solid #4E8CC9;

}
.navBg{
  background-color: white;
}
.banner-img{
  text-align: right;
}
#banner .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down{
0% {
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
}
100% {
  -webkit-transform: translatey(0px);
          transform: translatey(0px);
}
}
@keyframes up-down{
  0% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
  }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  }
  .homebanner_bg{
    background-color: #B3E0FF;
    padding-bottom: 20px;
  }
  .aboutbanner_bg{
    background-color: #B3E0FF;
    padding-bottom: 20px;
  }
  .contactbanner_bg{
    background-color: #B3E0FF;
    padding-bottom: 20px;
  }

.footer_bg{
  background-color: #F5F5F6;
  padding-top:20px;
}
.footer_bg h3{
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  color: #4154f1;
  text-transform: uppercase;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 30px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.counts .count-box i {
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
  color: #4154f1;
}

.counts .count-box span {
  text-align: center;
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #0b198f;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  text-align: right;
  font-size: 16px;
}

.cta{
  background: rgba(23, 61, 122,1);
  padding: 80px 0 60px 0;
}
.cta p {
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 38px;
  font-weight: 700;
  color: rgba(255, 255, 255);
}
.cta .btn-contact{
  font-weight:500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  background: #4E8CC9;
  color: #fff;
  border: 2px solid#4E8CC9;
  text-decoration: none;
}
.cta .btn-contact:hover{
  background: #fff;
  color: #4E8CC9;
  border: 2px solid #4E8CC9;

}
  /*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  margin-top: 20px;
  color: #444444;
  text-align: center;
  box-shadow: 0 0 15px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
  background: #fff;
}

.contact .info-box i {
  font-size: 32px;
  color: #5846f9;
  border-radius: 50%;
  padding: 8px;
}

.contact .info-box h4 {
  font-size: 18px;
  color: #2c4964;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

::selection{
  color: #fff;
  background: #0D6EFD;
}
.wrapper{
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}
.wrapper header{
  font-size: 22px;
  font-weight: 600;
  padding: 20px 30px;
  border-bottom: 1px solid #ccc;
}
.wrapper form{
  margin: 35px 30px;
}
.wrapper form.disabled{
  pointer-events: none;
  opacity: 0.7;
}
form .dbl-field{
  display: -webkit-flex;
  display: flex;
  margin-bottom: 25px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.dbl-field .field{
  height: 50px;
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: calc(100% / 2 - 13px);
}
.wrapper form i{
  position: absolute;
  top: 50%;
  left: 18px;
  color: #ccc;
  font-size: 17px;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
form .field input,
form .message textarea{
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0 18px 0 48px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.field input::-webkit-input-placeholder, .message textarea::-webkit-input-placeholder{
  color: #ccc;
}
.field input:-ms-input-placeholder, .message textarea:-ms-input-placeholder{
  color: #ccc;
}
.field input::placeholder,
.message textarea::placeholder{
  color: #ccc;
}
.field input:focus,
.message textarea:focus{
  padding-left: 47px;
  border: 2px solid #0D6EFD;
}
.field input:focus ~ i,
.message textarea:focus ~ i{
  color: #0D6EFD;
}
form .message{
  position: relative;
}
form .message i{
  top: 30px;
  font-size: 20px;
}
form .message textarea{
  min-height: 130px;
  max-height: 230px;
  max-width: 100%;
  min-width: 100%;
  padding: 15px 20px 0 48px;
}
form .message textarea::-webkit-scrollbar{
  width: 0px;
}
.message textarea:focus{
  padding-top: 14px;
}
form .button-area{
  margin: 25px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.button-area button{
  color: #fff;
  border: none;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  padding: 13px 25px;
  background: #0D6EFD;
  transition: background 0.3s ease;
}
.button-area button:hover{
  background: #025ce3;
}
.button-area span{
  font-size: 17px;
  margin-left: 30px;
  display: none;
}
@media (max-width: 600px){
  .wrapper header{
    text-align: center;
  }
  .wrapper form{
    margin: 35px 20px;
  }
  form .dbl-field{
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0px;
  }
  form .dbl-field .field{
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
  }
  form .message textarea{
    resize: none;
  }
  form .button-area{
    margin-top: 20px;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .button-area button{
    width: 100%;
    padding: 11px 0;
    font-size: 16px;
  }
  .button-area span{
    margin: 20px 0 0;
    text-align: center;
  }
}
/* .contact .php-email-form h4 {
  font-size: 18px;
  color: #2c4964;
  font-weight: 700;
  margin: 10px 0;
}
.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 5px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #5846f9;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #4F8DC9;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 5px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #173D7A;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.footmenu li a{
  text-decoration: none;
  color: #212529;
  padding-bottom: 20px;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
/* section {
  overflow: hidden;
}

.section-bg {
  background-color: #fff;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #4E8CC9 ;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
} */

/*--------------------------------------------------------------
# Values
--------------------------------------------------------------*/
.values .box {
  padding: 30px;
  box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
  text-align: center;
  transition: 0.3s;
  height: 100%;
}

.values .box img {
  padding: 30px 50px;
  transition: 0.5s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.values .box h3 {
  font-size: 24px;
  color: #012970;
  font-weight: 500;
  margin-bottom: 18px;
}

/* @media (max-width: 768px){
.section-header p {
    font-size: 28px;
    line-height: 32px;
}
} */

.values .box:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.values .box:hover img {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.section-header h2 {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  color: #4154f1;
  text-transform: uppercase;
  text-align: center;
}
.section-header p {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
  color: #012970;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.section-title h2{
    margin: 20px 0 20px 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #012970;
    text-align: center;
}
.sdata h4{
  font-size: 24px;
  color: #012970;
  font-weight: 500;
  margin-bottom: 18px;
}
.services .icon-box {
  text-align: center;
  padding: 70px 20px 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}

.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}

.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: #B3E0FF;
}

.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 22px;
}

.services .icon-box h4 a {
  color: #2c4964;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 32px;
  
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
}

.services .iconbox-blue i {
  color: #47aeff;
}

.services .iconbox-blue:hover .icon i {
  color: #fff;
}

.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
 /* .clients .clients-slider .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
}

.clients .clients-slider .swiper-slide img:hover {
  opacity: 1;
}

.clients .clients-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #4154f1;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #4154f1;
}
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swiper-container-vertical>.swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
} */

/*--------------------------------
 Clients Section
--------------------------------*/
#clients {
  padding: 60px 0;
  box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

#clients .clients-wrap {
  border-top: 1px solid #d6eaff;
  border-left: 1px solid #d6eaff;
  margin-bottom: 30px;
}

#clients .client-logo {
  padding: 64px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-right: 1px solid #d6eaff;
  border-bottom: 1px solid #d6eaff;
  overflow: hidden;
  background: #fff;
  height: 160px;
}

#clients .client-logo:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

#clients img {
  transition: all 0.4s ease-in-out;
}
.clients-bg {
  background-color: #d4ecff;
}

/*--------------------------------
 Services Page
--------------------------------*/
.servicehead h2{
  text-align: center;
  font-size: 28px;
  color: #012970;
  font-weight: 800;
  margin-bottom: 18px;
}
.servicehead{
  position: relative; /* Center it */
  max-width: 800px; /* Maximum width */
  margin: 0 auto; /* Center it */
  text-align: center;
}
.servicedata{
 margin: 0;
 padding: 0;
 height: auto;
 text-align: justify;
 line-height: 1.5rem;
 letter-spacing: 1px;
 float: left;
}
/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .feature-box {
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
}

.features .feature-box h3 {
  font-size: 18px;
  color: #012970;
  font-weight: 700;
  margin: 0;
}

.features .feature-box i {
  line-height: 0;
  background: #ecf3ff;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.features .feature-box:hover i {
  background: #4154f1;
  color: #fff;
}

.features .feature-icons {
  margin-top: 30px;
}

.features .feature-icons h3 {
  color: #012970;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .features .feature-icons h3 {
    font-size: 28px;
  }
}

.features .feature-icons .content .icon-box {
  display: -webkit-flex;
  display: flex;
}

.features .feature-icons .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #012970;
}

.features .feature-icons .content .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: #0245bc;
  margin-right: 15px;
}

.features .feature-icons .content .icon-box p {
  font-size: 15px;
  color: #848484;
}

